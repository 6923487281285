import React from 'react'
import { Form, Formik, Field } from 'formik'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ButtonProgress from '../ButtonProgress'

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <TextField
    {...field}
    {...props}
    helperText={touched[field.name] && errors[field.name]}
    error={touched[field.name] && Boolean(errors[field.name])}
    fullWidth
  />
)

const CredentialsForm = ({
  inputFields,
  initialValues,
  validationSchema,
  handleSubmit,
  submitText,
  loading,
  children
}) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      px={2}
      alignSelf='center'
      maxWidth='350px'
      minWidth='300px'
      width='100%'
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid, status }) => {
          return (
            <Form>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
              >
                {inputFields.map(({ label, name, type }) =>
                  type === 'checkbox' ? (
                    <FormControlLabel
                      control={<Checkbox key={name} name={name} fullWidth />}
                      label={label}
                    />
                  ) : (
                    <Field
                      key={name}
                      label={label}
                      type={type}
                      name={name}
                      fullWidth
                      component={CustomInputComponent}
                    />
                  )
                )}
                {children}
              </Box>
              <Box my={2}>
                <Button
                  color='primary'
                  variant='contained'
                  disableElevation
                  fullWidth
                  type='submit'
                  disabled={!isValid}
                >
                  {submitText}
                  {(isSubmitting || loading) && (
                    <ButtonProgress size={24} color='primary' />
                  )}
                </Button>
              </Box>
              {status && (
                <Typography variant='caption' color='error'>
                  {status}
                </Typography>
              )}
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default CredentialsForm
